export const environment = {
  isReleaseBuild: false,
  envName:'DEV',
  isDev:true,
  enableDebug: false,
  useCache:false,
  useProxy:true,
  baseApiUrl:'/Services/MessengerDev/',
  host:'https://webservicesmo.energytransfer.com',
  port:':80',
  secondaryUrl:'https://webservicesmo.energytransfer.com',
  infoPostBaseUrl: 'http://mo.peplmessenger.energytransfer.com/mo/ipost',
  extSecurityUrl: 'https://mo.security.energytransfer.net/'
};
